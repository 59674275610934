import Event from 'models/Event'
import Ticket from 'models/Ticket'
import useSWR from 'swr'
import AuthService, { addSearchParams, ckFetcher, useSWRPaginated } from './AuthService'
import { EVENTS, ME, MY_EVENT_TICKETS_ROUTE, TICKETS, USERS } from './Constants'
import { Zoom, toast } from 'react-toastify'

// const fetcher = (...args) => fetch(...args).then(res => res.json())

// Add this interface to define the structure of a ticket sale record
interface TicketSale {
    ticket_type: string
    ticket_price: number
    user_email: string
    total_tickets: number
    scanned_tickets: number
    unscanned_tickets: number
    total_revenue: number
    first_purchase: string
    last_purchase: string
}

// Add this to your existing exports
export function useTicketSalesOverview(event: Event) {
    const { data, error, ...props } = useSWR<TicketSale[]>(
        event ? `${EVENTS}/${event.$slug}/${TICKETS}/sales` : null,
        ckFetcher,
        {
            refreshInterval: 60000, // 60 seconds = 1 minute
        }
    )

    return {
        sales: data ?? [],
        isLoading: !error && !data,
        isError: error,
        ...props,
    }
}

export function useEventTickets(event: Event) {
    const { data, error, ...props } = useSWR(`${EVENTS}/${event.$slug}/${TICKETS}`, ckFetcher)

    const _tickets: Ticket[] = []
    if (data) {
        for (let i = 0; i < data.length; i++) {
            _tickets.push(Ticket.fromJSON(data[i]))
        }
    }
    return {
        tickets: _tickets,
        isError: error,
        ...props,
    }
}

export interface IMyTickets {
    event?: Event
    transaction?: string
    filter?: 'THIS_WEEK' | 'UPCOMING' | 'PAST' | 'ALL'
    hash?: string
}

export function useMyTickets({ event, transaction, filter, hash }: IMyTickets) {
    const path = addSearchParams(`${USERS}/${ME}/${TICKETS}`, {
        event: event?.$id,
        transaction,
        filter,
        hash
    })
    const { data, error, ...props } = useSWRPaginated(path)

    const events: Event[] = []
    if (data) {
        for (let i = 0; i < data.length; i++) {
            events.push(Event.fromJSON(data[i]))
        }
    }
    return {
        events,
        isError: error,
        ...props,
    }
}

export function useMyEventTickets({ event, transaction, filter, hash }: IMyTickets) {
    const path = addSearchParams(MY_EVENT_TICKETS_ROUTE(event), {
        transaction,
        filter,
        hash
    })
    const { data, error: isError, ...props } = useSWR(path)

    const eventTickets: Ticket[] = []
    if (data) {
        for (let i = 0; i < data.length; i++) {
            eventTickets.push(Ticket.fromJSON(data[i]))
        }
    }
    return {
        tickets: eventTickets ?? [],
        isError,
        ...props,
    }
}

export async function buyTickets(event: Event, basket: { [key: string]: number }, email?: string): Promise<string> {
    const parsedBasket = Object.keys(basket)
        .map((key) => {
            if (basket[key] === 0) return null
            return {
                id: key,
                amount: basket[key],
            }
        })
        .filter((item) => item !== null)
    const body = { tickets: parsedBasket }
    if (email) {
        body['email'] = email
    }
    const resp = await AuthService.post(`${EVENTS}/${event.$slug}/${TICKETS}/buy`, body)
    return resp.data
}

export async function scanTicket(event: Event, ticketId: string): Promise<string> {
    try {
        const resp = await AuthService.post(
            `${EVENTS}/${event.$slug}/${TICKETS}/${ticketId}/scan`,
            {}
        )
        return resp.data
    } catch (error) {
        const errorCode = error.response.data.error
        const errorData = error.response.data.data

        if (errorCode === 'NOT_FOUND') {
            throw new Error(`Ticket niet geldig voor dit evenement`)
        }

        if (errorCode === 'ALREADY_SCANNED') {
            const scannedAt = new Date(errorData.scanned_at).toLocaleString()
            throw new Error(`Ticket is al gescand op ${scannedAt}`)
        }
        throw new Error(error.response.data.message)
    }
}

export async function sendTicketHelpRequest(event: Event, email: string) {
    try {
        const resp = await AuthService.post(`${EVENTS}/${event.$slug}/${TICKETS}/help`, { email })

        toast.success(resp.data, {
            containerId: 'B',
            transition: Zoom,
        })
    } catch (error) {
        console.error(error)
        toast.error('Er is iets misgegaan bij het versturen van het hulpverzoek.', {
            containerId: 'B',
            transition: Zoom,
        })
    }
}
